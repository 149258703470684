.upcomingEventsContainer {
  background-image: url("/public//backgroundImage//image.jpeg");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.thakyouMessageContainer {
  background-image: url("/public/backgroundImage/image.jpeg");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
