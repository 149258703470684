.headerImageContainer {
  background-image: url("/public/whyPage//backgroundImage.png");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.description {
  font-family: "Roboto Slab", serif;
}
