.header {
  font-family: "Merriweather", serif;
  font-weight: bolder !important;
}

.upcomingEventsContainer {
  background-image: url("/public/backgroundImage/blueImage.jpg");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
