.footerContainer {
  background-image: url("/public//backgroundImage/blueImage.jpg");
  /* background-attachment: fixed; */
  background-size: cover;
  position: relative;
}

.secondaryText {
  font-family: "Roboto Slab", serif !important;
}
