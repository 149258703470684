.videoSection {
  background-image: url("https://images.pexels.com/photos/6180458/pexels-photo-6180458.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.joinOurClubSection {
  background-image: url("/public//backgroundImage//image.jpeg");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.secondaryText {
  font-family: "Roboto Slab", serif;
}
