.masnoryGrid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  width: auto;
}

.masnoryGridColumn {
  padding-left: 30px;
  background-clip: padding-box;
}

.secondaryText {
  font-family: "Roboto Slab", serif !important;
}
