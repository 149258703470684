.homeImageContainer {
  background-image: url("/public/backgroundImage/blueImage.jpg");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.container {
  background-color: antiquewhite !important;
}
