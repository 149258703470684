.title {
  font-family: "Merriweather", serif;
  font-weight: bolder !important;
}

.description {
  font-family: "Roboto Slab", serif;
  letter-spacing: 0.4px;
}

.hilightedText {
  font-family: "Wild Wolf", sans-serif;
}
