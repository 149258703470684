@tailwind base;
@tailwind components;
@tailwind utilities;

/* Header */
body,
h4 {
  font-family: "Merriweather", serif;
}

@layer components {
  .tableHeader {
    @apply text-[0.8rem] px-10;
  }

  .tableBody {
    @apply px-10 mt-1 bg-white py-5 rounded-sm shadow-sm tracking-wide;
  }

  .modalTableBody {
    @apply px-10 mt-1 bg-white py-5 rounded-sm shadow-sm tracking-wide;
  }

  .siteMap {
    @apply cursor-pointer text-[0.9rem];
  }

  .formInput {
    @apply w-full border border-gray-100 px-5 py-2 rounded-md mt-2 outline-none;
  }

  .contactInput {
    @apply w-full outline-none px-5 py-4 rounded-md border border-[#00000059] bg-transparent;
  }

  .clubDetailsHeader {
    @apply text-primary text-[1.5rem];
  }

  .widgitIcons {
    @apply bg-white px-2 py-2 rounded-full cursor-pointer transition transform hover:scale-125 ease-in-out;
  }

  .largeActionButton {
    @apply bg-primary px-9 py-5 lg:px-12 lg:py-5 md:text-[1.1rem] w-[300px] lg:text-[1.3rem] text-white rounded-full;
  }

  .hypeMessageBackground {
    @apply bg-white rounded-md px-7 py-5;
  }
}
